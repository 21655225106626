import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { IRequest } from "../components/roam-fetch-table/roam-fetch-table.component";

@Injectable({
  providedIn: "root",
})
export class DocumentService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  documentUser(path: string = ''): Observable<any> {
    const url = `${this.apiUrl}/users/files/${path}`;
    return this.http.get<any>(url, { withCredentials: true });
  }

  public getDocuments = (request: IRequest): Observable<any> => {
    const url = `${this.apiUrl}/documents?page=${request.pageIndex}&limit=${request.pageSize}`;
    return this.http.get<any>(url, { withCredentials: true });
  };
  public getFolder = (propertyId: string, path: string = ''): Observable<any> => {
    const url = `${this.apiUrl}/properties/${propertyId}/folders/${path}`;
    return this.http.get<any>(url, { withCredentials: true });
  };
  public getResidentDocumentFolder = (path: string = ''): Observable<any> => {
    const url = `${this.apiUrl}/users/documents/${path}`;
    console.log('url', url);
    return this.http.get<any>(url, { withCredentials: true });
  };
}
