import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  standalone: true,
  selector: "app-button-file",
  template: `
    <div class="file-input-container" [class]="class">
      <input
        type="file"
        id="fileInput"
        class="file-input"
        (change)="changeFile($event)" />
      <div class="align-center">
        @if (icon) {
          <img [src]="icon" alt="open file" class="mr-8" />
        }
        <label for="fileInput" class="file-input-label">
          {{ label }}
        </label>
      </div>
    </div>
  `,
  styles: [
    `
      .file-input-container {
        position: relative;
        width: fit-content;
        overflow: hidden;
        display: flex;
        align-items: center;
      }

      .file-input {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: red;
        left: 0;
        right: 0;
      }

      .file-input-label {
        color: inherit;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 500 !important;
      }
    `,
  ],
})
export class ButtonFileComponent {
  @Input()
  public label: string = "";

  @Input()
  public icon: string = "";

  @Input()
  public class: string = "";

  @Output()
  public onChange: EventEmitter<Event> = new EventEmitter<Event>();

  public changeFile(event: Event): void {
    this.onChange.emit(event);
  }
}
